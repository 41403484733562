@font-face {
  font-family: Allianz-Neo;
  src: url(assets/font/allianz-neo-new/AllianzNeo-Regular.otf) format("opentype");
}

* {
  font-family: 'Allianz-Neo', 'arial', sans-serif;
}

.allianz-background {
  background-image: url("assets/Background-eForm-1.jpg");
  height: 870px;
  margin-bottom: 0;
}

.allianz-background-faded {
  background-image: url("assets/Background-eForm-Fade.jpg");
  height: 870px;
  margin-bottom: 0;
}

.allianz-image-footer {
  width: 20px;
}

.no-border {
  border: none;
}

.bottom-border {
  border: none; /* Remove the default border */
  border-bottom: 1px solid #ccc; /* Add a bottom border with your preferred color */
  border-radius: 0;
}

.allianz-progress-step {
  width: 23%;
  height: 9px;
  text-align: center;
  border-radius: 3px;
}

.step-active {
  background-color: #0f5091;
}

.step-inactive {
  background-color: #bfcee1;
}

.form-content {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  box-shadow: #eefa5a;
}

.form-container {
  background-color: #f4f7fa;
}

.form-header {
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  background-color: #ebeef5;
}

.info-box {
  background-color: #fdf8e9;
  border-radius: 8px;
}

.description-input {
  color: #017AB2;
  font-style: italic;
  font-size: 12px;
}

.btn-continue {
  background-color: #003780;
  color: white;
}

.btn-login {
  background-color: #003780;
  color: white;
  padding: 15px 80px;
  margin: 15px 4px;
}

.btn-back {
  color: #003780;
  background-color: white;
  border-color: #003780FF;
}

.button-outline-primary {
  color: #003780;
  background-color: white;
  border: 2px solid #003780FF;
}

.button-outline-primary:hover {
  color: white;
  background-color: #003780;
  border: 2px solid #003780FF;
}

.button-primary:hover {
  color: #003780;
  background-color: white;
  border: 2px solid #003780FF;
}

.button-primary {
  color: white;
  background-color: #003780;
  border: 2px solid #003780FF;
}

.flex-gap {
  gap: 10px
}

.bg-dark-blue {
  background-color: #0079b2;
}

.bg-gray {
  background-color: #eaeaea;
}

.color-light-blue {
  color: #007AB3 !important;
}


.color-dark-blue {
  color: #001B3F;
}

.color-blue {
  color: #00468A;
}

.bold {
  font-weight: bold;
}

.signature-pad-canvas {
  border-style: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-justify {
  text-align: justify;
}

.text-center {
  text-align: center !important;
}

ul.disc {
  list-style-type: disc;
}

ol.lower-roman {
  list-style-type: lower-roman;
}

ol.lower-alpha {
  list-style-type: lower-alpha;
}

.question-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.question-text {
  width: 300px;
  /*text-align: justify;*/
  /*margin-right: 10px;*/
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #007AB3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.big-checkbox {
  width: 20px;
  height: 20px;
}

.btn-reset {
  color: white;
  width: fit-content;
  height: fit-content;
  border: none;
  border-radius: 8px;
}

.color-dark-orange {
  color: #DC6803;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-14 {
  font-size: 14px;;
}
